import { reactive } from "vue"
import TicketsRepository from '@/api/ticketsRepository'

export const newTicketAction = (modal: any) => {
  return {
    title: 'Ticket erstellen',
    icon: 'ticket-alt',
    tooltip: 'Neues Ticket erstellen',
    onClick: () => modal.value.show()
  }
}

  import Vue from 'vue'
  import { defineComponent, reactive, ref, computed, getCurrentInstance } from "vue"
  import FormStepper from "@/components/shared/FormStepper.vue"
  import SchemaForm from "@/components/shared/form/SchemaForm.vue"
  import { getKeycloak } from "@/plugins/keycloak";
  import { intersect } from "@/utils/helper";

  interface Form {
    attributes: {
      category: string,
      subject: string,
      ticketableType: string,
      ticketableId: string,
      messages: {
        payload: string,
        mailIncluded: boolean
      }[],
      subscriptions: any[],
      fileMetaDatas: string[]
    }
  }

  export default defineComponent({
    name: "TicketEdit",
    components: {
      FormStepper,
      SchemaForm
    },
    props: {
      ticketableType: {
        type: String,
        required: true
      },
      ticketableId: {
        type: String,
        required: true
      }
    },
    setup(props, { emit }) {
      const root = getCurrentInstance().proxy
      const showLoading = ref(false)
      const keycloakRef = getKeycloak()
      const role = keycloakRef.value.tokenParsed?.resource_access?.['pflegehilfe-senioren-platform']['roles'][0]
      const ticketCategories = ref<string[]>([])

      const all = ['Innendienst', 'Vertrieb', 'Agentur']
      const int = ['Innendienst', 'Vertrieb']
      const out = ['Vertrieb', 'Agentur']
      const availableSubscriptions = ():string[] => {
        switch (props.ticketableType) {
          case 'leads':
            return int
            break
          case 'households':
            return int
            break
          case 'contracts':
            return all
            break
          case 'postings':
            return int
            break
          case 'visors':
            return all
            break
          default:
            return all
        }
      }

      const preselectedSubscriptions = ():string[] => {
        switch (role) {
          case 'internal':
            return all
            break
          case 'seller':
            if (['leads', 'households', 'postings'].includes(props.ticketableType)) {
              return int } else { return out }
            break
          case 'assistant':
            return out
            break
          case 'provider':
            return out
            break
          default:
            return all
        }
      }
      const subscriptions = intersect(availableSubscriptions(), preselectedSubscriptions())
      const ticket = reactive({
        attributes: {
          category: '',
          subject: '',
          ticketableType: props.ticketableType,
          ticketableId: props.ticketableId,
          messages: [],
          subscriptions: subscriptions,
          fileMetaDatas: []
        }
      })

      const uploadedFiles = ref<any[]>([])

      const processUppyResult = (result) => {
        uploadedFiles.value = uploadedFiles.value.concat(result.successful.map(suc => suc.response.body.data[0]))
      }

      const uppy = () => {
        return import('@/components/shared/Uppy.vue')
      }

      const uppyComponent = Vue.component('uppyComponent', {
        render(h) {
          return h(uppy, {
            props: {
              maxNumberOfFiles: null,
            },
            on: {
              complete: processUppyResult
            },
          })
        }
      })


      const form = ref<Form>(ticket)

      const formSchema = {
        $schema: "https://json-schema.org/draft/2019-09/schema",
        $id: "https://crm.pflegehilfe-senioren.de/ticket-model.json",
        type: "object",
        properties: {
          attributes: {
            type: "object",
            title: "Attributes",
            properties: {
              category: { type: "string", title: "Kategorie" },
              ticketableType: { type: "string" },
              ticketableId: { type: "string" },
              subject: { type: "string", title: "Subject" },
              priority: { type: "boolean" },
              messages: {
                type: "array",
                items: {
                  type: "object",
                  properties: {
                    payload: { type: "string" },
                    mailIncluded: { type: "boolean" }
                  }
                }
              },
              subscriptions: {
                type: "array",
                items: { type: "string" }
              },
              fileMetaDatas: {
                title: "Anhänge",
                type: "array",
                items: {
                  type: "string",
                }
              },       
            }
          }
        }
      }

      const uiSchema = ref({
        $schema: "https://json-schema.org/draft/2019-09/schema",
        $id: "https://crm.pflegehilfe-senioren.de/ticket-view.json",
        $vocabulary: {
          "https://json-schema.org/draft/2019-09/vocab/core": true,
          "https://crm.pflegehilfe-senioren.de/ui-vocab": true
        },
        "ui:type": "form",
        "ui:widget": "b-row",
        type: "object",
        properties: {
          attributes: {
            type: "object",
            $ref: "https://crm.pflegehilfe-senioren.de/ticket-model.json#/properties/attributes",
            "ui:type": "group",
            "ui:label": "",
            properties: {
              subscriptions: {
                "ui:type": "collection",
                "ui:widget": "v-select",
                "ui:label": "Benachrichtigungen für",
                "ui:options": {
                  multiple: true,
                  options: availableSubscriptions(),
                },
                $ref: "https://crm.pflegehilfe-senioren.de/ticket-model.json#/properties/attributes/properties/subscriptions",
                items: {
                  type: "string",
                  $ref: "https://crm.pflegehilfe-senioren.de/posting-model.json#/properties/attributes/properties/subscriptions/items"
                }
              },
              category: {
                "ui:widget": "v-select",
                "ui:options": {
                  options: ['keine'],
                },
                $ref: "https://crm.pflegehilfe-senioren.de/ticket-model.json#/properties/attributes/properties/category"
              },
              subject: {
                "ui:label": "Betreff",
                $ref: "https://crm.pflegehilfe-senioren.de/ticket-model.json#/properties/attributes/properties/subject"
              },
              priority: {
                "ui:label": "Dringend! (Bitte nicht mißbrauchen)",
                "ui:type": "checkbox",
                $ref: "https://crm.pflegehilfe-senioren.de/ticket-model.json#/properties/attributes/properties/priority"
              },
              messages: {
                type: "array",
                "ui:type": "collection",
                $ref: "https://crm.pflegehilfe-senioren.de/ticket-model.json#/properties/attributes/properties/messages",
                "ui:options": {
                  singleElement: true
                },
                items: {
                  type: "object",
                  "ui:type": "group",
                  $ref: "https://crm.pflegehilfe-senioren.de/ticket-model.json#/properties/attributes/properties/messages/items",
                  properties: {
                    payload: {
                      "ui:label": "Nachricht",
                      "ui:widget": "b-form-textarea",
                      $ref: "https://crm.pflegehilfe-senioren.de/ticket-model.json#/properties/attributes/properties/messages/items/properties/payload",
                    }
                    // mailIncluded: {
                    //   "ui:label": "Mail included",
                    //   "ui:type": "checkbox",
                    //   $ref: "https://crm.pflegehilfe-senioren.de/ticket-model.json#/properties/attributes/properties/messages/items/properties/mailIncluded"
                    // }
                  }
                }
              },
              fileMetaDatas: {
                type: "array",
                $ref: "https://crm.pflegehilfe-senioren.de/ticket-model.json#/properties/attributes/properties/fileMetaDatas",
                "ui:classNames": ["col-sm-12", "col-md-6"],
                "ui:type": "collection",
                "ui:widget": "uppyComponent",
                items: {
                  $ref: "https://crm.pflegehilfe-senioren.de/ticket-model.json#/properties/attributes/properties/fileMetaDatas/items",
                  type: "string",
                }
              }
            }
          }
        }
      })

      const getTicketCategories = async () => {
        const listQuery = {'filter[sys_id]': 'ticket_categories',include: 'sublists'}
        await root.$store.dispatch('lists/load', listQuery)
        const response = root.$store.state.lists.response
        const categories = (response.included||[]).map((sl) => sl.attributes?.name) as string[]
        uiSchema.value.properties.attributes.properties.category['ui:options'].options = ['keine', ...categories]
      }
      getTicketCategories()
      const updatedForm = (formValue) => form.value = formValue

      const submitForm = async () => {
        let formValue = { ...form.value }
        formValue.attributes.fileMetaDatas = uploadedFiles.value.map(file => file.id)
        // let allSubscribers: { id: string }[] = []
        // formValue.attributes.subscriptions.forEach(sub => allSubscribers.push(...props.ticketData.subscribers[sub]))
        // formValue.attributes.subscriptions = allSubscribers.map(subscriber => ({ protoUserId: subscriber.id }))
        showLoading.value = true
        await root.$store.dispatch('tickets/create', form.value)
        emit('edited')
        showLoading.value = false
      }

      return {
        form,
        formSchema,
        uiSchema,
        updatedForm,
        submitForm,
        ticket,
        ticketCategories,
        uploadedFiles,
        showLoading
      }
    }
  })
